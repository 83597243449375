/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

/**
 * Bottom navigation
 */
nav.navigation {

    .nav-previous {
        float: left;
    }

    .nav-next {
        float: right;
    }
}

.rss-subscribe, .post-list {
    clear: both;
}


/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;

    blockquote {
        margin-top: 0em;
        margin-bottom: 0em;
    }
}

@media (prefers-color-scheme: dark) {
    .site-footer {
        border-top: 1px solid $grey-color-dark;
    }
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }

    .post-head {
        margin-bottom: $spacing-unit / 2;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    font-size: 24px;
    margin-right: $spacing-unit / 2;
}

.post-link-external:before {
    content: " ☛";
}

.post-note {
    margin-right: $spacing-unit / 2;
}


/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

.image-block {
    display: block;
    margin: $spacing-unit auto;
}

/**
 * Microposts
 */

.microblog-posts {

}

.microblog-post {
    margin-bottom: $spacing-unit;
}

.microblog-post, .microblog-post-alone {
    p {
        margin-bottom: 0px;
    }

    footer {
        text-align: left;
        
        a {
            color: grey;
        }
        a:visited {
            color: #9f9f9f;
        }
    }
}

.microblog-post-alone {
    padding-top: 0;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 150px;

    @include media-query($on-laptop) {
        padding: $spacing-unit;
    }

}
